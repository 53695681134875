@import "_fonts.scss";

$font_RUBSN: RUBSN;

// primary
:root {
    --color-body: #FBFBFB;
    --color-black-1: #222222;
    --color-black-2: #131313;
    --color-black-3: #6B6B6B;
    --color-black-4: #373737;
    --color-black-5: #5E5E5E;
    --color-light-1: #C5C5C5;
    --color-light-2: #C3C3C3;
    --color-light-3: #7D6B5D;
    --color-light-4: #DAD5D2;
    --color-light-5: #A4A4A4;
    --color-red-1: #AD9C8A;
    --color-red-2: #D9D9D9;
    --color-red-3: #F2EEEA;
    --color-red-4: #938A80;
    --color-red-5: #857869;
    --color-red-6: #D3CFC8;
    --color-red-7: #D5CCC3;
    --color-red-8: #ABA095;
    --color-green-1: #3D7F50;
}

:root{
    --indent-1: 10px;
    --indent-1a: 12px;
    --indent-1b: 20px;
    --indent-2: 25px;
    --indent-3: 30px;
    --indent-4: 35px;
    --indent-4b: 50px;
    --indent-5: 60px;

    --font-family: Muller;

    --font-family-namu: Namu_Pro;
    --font-family-namu-1850: Namu_1850;
    --font-family-namu-1960: Namu_1960;
    --font-family-namu-pro: Namu_pro;

    --font-family-tenor: TenorSans;
    --font-family-bebas: BebasNeue;

    --font-family-muller: Muller;
    --font-family-muller-bold: Muller_Bold;
    --font-family-muller-light: Muller_Light;
    --font-family-muller-medium: Muller_Medium;

    --font-size: 18px;
    --font-size-xs: 10px;
    --font-size-sm: 12px;
    --font-size-md: 14px;
    --font-size-lg: 16px;       // h4
    --font-size-lgl: 18px;      
    --font-size-xl: 20px;       
    --font-size-2xl: 22px;      // 
    --font-size-3xl: 24px;      // h4
    --font-size-4xl: 35px;      // h3
    --font-size-5xl: 40px;      // h2
    --font-size-5xlb: 46px;      
    --font-size-6xl: 60px;      // h1
    --font-size-7xl: 80px;      // rezerv
    --font-size-8xl: 100px;      // h1

    --line-height-1: 138,5%;

    --color: var(--color-black-1);                // #222222
    --color-normal: var(--color-black-1);         // #222222
    --color-white: #ffffff;                     // #ffffff
    --color-link: var(--color-normal);          
    --color-active: var(--color-red-4);          
    --color-hover: var(--color-red-1);           
    --color-primary: var(--color-normal);         
    --color-bg-strong: var(--color-light-2);       
    --color-bg-img: var(--color-red-1);          
    --color-error: var(--color-normal);            // #AF1B3F

    --rgba-01: rgba(125, 107, 93, 0.24);
    --rgba-02: rgba(255,255,255,0.1);
    --rgba-03: rgba(0,0,0,0.2);

}
