@font-face{
    font-family: TenorSans;
    display: swap;
    font-display: swap;
    src: url("../fonts/TenorSans-Regular.ttf") format("opentype");
}

@font-face{
    font-family: Muller;
    display: swap;
    font-display: swap;
    src: url("../fonts/muller/MullerRegular.woff") format("woff"),
        url("../fonts/muller/MullerRegular.woff2") format("woff2"),
        url("../fonts/muller/MullerRegular.eot"),
        url("../fonts/muller/MullerRegular.ttf") format("opentype");
}
@font-face{
    font-family: Muller_Bold;
    display: swap;
    font-display: swap;
    src: url("../fonts/muller/MullerBold.woff") format("woff"),
        url("../fonts/muller/MullerBold.woff2") format("woff2"),
        url("../fonts/muller/MullerBold.eot"),
        url("../fonts/muller/MullerBold.ttf") format("opentype");
}
@font-face{
    font-family: Muller_Light;
    display: swap;
    font-display: swap;
    src: url("../fonts/muller/MullerLight.woff") format("woff"),
        url("../fonts/muller/MullerLight.woff2") format("woff2"),
        url("../fonts/muller/MullerLight.eot"),
        url("../fonts/muller/MullerLight.ttf") format("opentype");
}
@font-face{
    font-family: Muller_Medium;
    display: swap;
    font-display: swap;
    src: url("../fonts/muller/MullerMedium.woff") format("woff"),
        url("../fonts/muller/MullerMedium.woff2") format("woff2"),
        url("../fonts/muller/MullerMedium.eot"),
        url("../fonts/muller/MullerMedium.ttf") format("opentype");
}

@font-face{
    font-family: Namu_Pro;
    display: swap;
    font-display: swap;
    src: url("../fonts/namu/NAMU-Pro.ttf") format("opentype");
}
@font-face{
    font-family: Namu_1850;
    display: swap;
    font-display: swap;
    src: url("../fonts/namu/NAMU-1850.ttf") format("opentype");
}
@font-face{
    font-family: Namu_1960;
    display: swap;
    font-display: swap;
    src: url("../fonts/namu/NAMU-1960.ttf") format("opentype");
}

@font-face{
    font-family: BebasNeue;
    display: swap;
    font-display: swap;
    src: url("../fonts/BebasNeue-Regular.ttf") format("opentype");
}

/*
@font-face{
    font-family: PTRootUI_Regular;
    display: swap;
    font-display: swap;
    src: url("../fonts/PT_Root_UI_Regular.woff") format("woff"),
        url("../fonts/PT_Root_UI_Regular.woff2") format("woff2"),
        url("../fonts/PT_Root_UI_Regular.eot"),
        url("../fonts/PT_Root_UI_Regular.ttf") format("opentype");
}
*/

// @font-face{
//     font-family: RUBSN;
//     display: swap;
//     src: url("../fonts/RUBSN.otf");
// }
















